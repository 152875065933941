import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const PaymentContext = createContext();

export const usePayment = () => useContext(PaymentContext);

export const PaymentProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [isCheckout, setIsCheckout] = useState(false); // Add this state
  
  useEffect(() => {
    if (isCheckout && paymentAmount) { // Only fetch client secret if isCheckout is true
      fetchClientSecret();
    }
  }, [paymentAmount, isCheckout]); // Add isCheckout as a dependency

  async function fetchClientSecret() {
    try {
      const response = await axios.post('https://admin.civis-libertatem.org/api/create-payment-intent', {
        amount : paymentAmount,
        currency: 'eur',
      });

      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.error('Error fetching client secret:', error);
    }
  }

  const updatePaymentAmount = (amount) => {
    setPaymentAmount(amount);
  };

  const updateIsCheckout = (checkout) => { // Add this update function
    setIsCheckout(checkout);
  };

  return (
    <PaymentContext.Provider value={{ clientSecret, updatePaymentAmount, updateIsCheckout }}>
      {children}
    </PaymentContext.Provider>
  );
};
