import React, { useContext, useState, useEffect } from 'react';
import { useFormContext } from "../../context/FormContext";
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, link } from 'react-router-dom';
import Select from 'react-select';
import countries from 'countries-list';

const CustomSelect = ({ field, form, options, ...props }) => {
    const handleChange = (value) => {
        form.setFieldValue(field.name, value);
    };

    const handleBlur = (evt) => {
        if ('ontouchstart' in window) {
            evt.preventDefault();
        } else {
            form.setFieldTouched(field.name, true);
        }
    };

    return (
        <Select
            {...field}
            {...props}
            options={options}
            onChange={handleChange}
            // onBlur={() => form.setFieldTouched(field.name, true)}
            onBlur={handleBlur}
        />
    );
};

const CustomError = ({ name, touched, errors }) => {
    if (!touched[name] || !errors[name]) return null;
    return <div className="text-danger">{errors[name]}</div>;
};
const countryOptions = Object.entries(countries.countries).map(([code, country]) => ({
    value: code,
    label: country.name
}));

function Form1() {

    
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { formData, updateFormData } = useFormContext();
    
    const navigate = useNavigate();

    useEffect(() => {
        if(!isAuthenticated){
          navigate('/');
        }
      }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nom est requis'),
        firstname: Yup.string().required('Prénom est requis'),
        date_birth: Yup.date().required('Date de naissance est requise'),
        country_birth: Yup.object().required('Pays de naissance est requis'),
        nationality: Yup.object().required('Nationalité est requis'),
        county_birth: Yup.string().required('Département de naissance est requis'),
        adress: Yup.string().required('Adresse est requise'),
        postcode: Yup.string().required('Code postal est requis'),
        city: Yup.string().required('Ville est requise'),
        phone: Yup.string().min(8, ({ min }) => `Le numero doit contenir minimum ${min} caractères`).required('Veuillez remplir ce champ'),
        city_birth: Yup.string().required('Ville de naissance est requise'),
        sex: Yup.object().required('Sexe est requis'),
        size: Yup.number().required('Taille est requise'),
        eyes_color: Yup.object().shape({ value: Yup.string().required('Couleur des yeux est requise') }),

    });

    return (
        <div className="containerfirsformid">
            <div className="containerFormId">
                <h1 className="titleform">Création d'une carte</h1>
                {/* <button className="btn btn-outline-secondary" type="submit" onClick={navigate("/form2")}>
                                    Suivant
                </button> */}
                <Formik
                    initialValues={{
                        name: '',
                        second_name: '',
                        firstname: '',
                        date_birth: '',
                        nationality: '',
                        second_nationality: '',
                        country_birth: '',
                        county_birth: '',
                        adress: '',
                        postcode: '',
                        city: '',
                        country: '',
                        phone: '',
                        city_birth: '',
                        sex: '',
                        size: '',
                        eyes_color: '',
                    }}
                    validationSchema={validationSchema}

                    onSubmit={async (values) => {
                        // localStorage.setItem("form1", values)
                        updateFormData(values);
                        navigate('/creation-carte-infos-parent');

                    }}>

                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="name">Nom</label>
                                    <Field className="input wInput" type="text" name="name" id="name" />
                                    <ErrorMessage className="text-danger" name="name" component="div" />
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="firstname">Prénom</label>
                                    <Field className="input" type="text" name="firstname" id="firstname" />
                                    <ErrorMessage className="text-danger" name="firstname" component="div" />
                                </div>
                            </div>

                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="second_name">Deuxiéme prénom</label>
                                    <Field className="input" type="text" name="second_name" id="second_name" />
                                    <ErrorMessage className="text-danger" name="second_name" component="div" />

                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="date_birth">Date de naissance</label>
                                    <Field className="input" type="date" name="date_birth" id="date_birth" />
                                    <ErrorMessage className="text-danger" name="date_birth" component="div" />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="nationality">Nationalité</label>
                                    <Field
                                        placeholder="Sélectionner"
                                        className="input_country"
                                        name="nationality"
                                        id="nationality"
                                        component={CustomSelect}
                                        options={[{ value: "FR", label: "Francaise" }]} // ne contient que l'option française

                                    />
                                   <CustomError name="nationality" touched={touched} errors={errors} />

                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="second_nationality">Seconde nationalité</label>
                                    <Field
                                        placeholder="Sélectionner"
                                        className="input_country"
                                        name="second_nationality"
                                        id="second_nationality"
                                        component={CustomSelect}
                                        options={countryOptions}
                                    />
                                    <CustomError name="second_nationality" touched={touched} errors={errors} />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="country_birth">Pays de naissance</label>
                                    <Field
                                        placeholder="Sélectionner"
                                        className="input_country"
                                        name="country_birth"
                                        id="country_birth"
                                        component={CustomSelect}
                                        options={countryOptions}
                                    />
                                    <CustomError name="country_birth" touched={touched} errors={errors} />
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="city_birth">Ville de naissance</label>
                                    <Field className="input" type="text" name="city_birth" id="city_birth" />
                                    <ErrorMessage className="text-danger" name="city_birth" component="div" />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="county_birth">Département de naissance</label>
                                    <Field className="input" type="text" name="county_birth" id="county_birth" />
                                    <ErrorMessage className="text-danger" name="county_birth" component="div" />
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="adress">Adresse</label>
                                    <Field className="input" type="text" name="adress" id="adress" />
                                    <ErrorMessage className="text-danger" name="adress" component="div" />
                                </div>
                            </div><div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="postcode">Code postal</label>
                                    <Field className="input" type="text" name="postcode" id="postcode" />
                                    <ErrorMessage className="text-danger" name="postcode" component="div" />
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="city">Ville</label>
                                    <Field className="input" type="text" name="city" id="city" />
                                    <ErrorMessage className="text-danger" name="city" component="div" />
                                </div>
                            </div>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="country">Pays</label>
                                    <Field className="input" type="text" name="country" id="country" />
                                    <ErrorMessage className="text-danger" name="country" component="div" />
                                </div>
                            </div>

                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="phone">Numéro de téléphone</label>
                                    <Field className="input" type="text" name="phone" id="phone" />
                                    <ErrorMessage className="text-danger" name="phone" component="div" />
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="eyes_color">Couleur des yeux</label>
                                    <Field
                                        placeholder="Sélectionner"
                                        className="input_country"
                                        name="eyes_color"
                                        id="eyes_color"
                                        component={CustomSelect}
                                        options={[
                                            { value: "bleu", label: "bleu" },
                                            { value: "vert", label: "vert" },
                                            { value: "marron", label: "marron" },
                                        ]}
                                    />
                                    <CustomError name="eyes_color" touched={touched} errors={errors} />
                                </div>
                            </div>

                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="sex">Sexe</label>
                                    <Field
                                        placeholder="Sélectionner"
                                        name="sex"
                                        id="sex"
                                        className="input_country"
                                        component={CustomSelect}
                                        options={[
                                            { value: "1", label: "homme" },
                                            { value: "2", label: "femme" }
                                        ]}
                                    />
                                    <CustomError name="sex" touched={touched} errors={errors} />
                                    {/* <ErrorMessage className="text-danger" name="sex" component="div" /> */}
                                </div>
                                <div className="d-flex flex-column p-3">
                                    <label htmlFor="size">Taille (cm)</label>
                                    <Field className="input" type="text" name="size" id="size" />
                                    <ErrorMessage className="text-danger" name="size" component="div" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-outline-secondary btn_sidebar widthBtnSidebar" type="submit">
                                    Suivant
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div >
        </div >

    );
}

export default Form1;