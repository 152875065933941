import React, { useContext, useEffect } from 'react';
import civisLogoo from '../../assets/images/civisLogoo.png';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';


function MenuTop() {
  const navigate = useNavigate();

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  return (
    <Navbar expand="lg" className="bgWhite">
      <Navbar.Brand>
        <img src={isAuthenticated ? civisLogoo : civisLogoo} className={ "logoAuth" } alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="mr-auto withsidebar ">
          <>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/">Accueil</a>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/a-propos-de-nous/">A propos de nous</a>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/documents-identites/">Documents ID</a>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/notre-action/">Notre action</a>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/articles/">Articles</a>
            <a className="navItem btn btn-outline-secondary btn_sidebar_top " href="https://www.civis-libertatem.org/contact/">Contact</a>
          </>
        </Nav>
      </Navbar.Collapse>
    </Navbar>


  );
}

export default MenuTop;