import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useFormContext } from "../../context/FormContext";
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import QMark from '../../assets/images/ask.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';

export default function Form3Image(props) {

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, []);

  const { formData, updateFormData } = useFormContext();

  const [identityPhotoFile, setIdentityPhotoFile] = useState('');
  const [identityCardFile, setIdentityCardFile] = useState('');
  const [proofOfResidenceFile, setProofOfResidenceFile] = useState('');
  const [selfieFile, setSelfieFile] = useState('');
  const [por_ext, setPor_ext] = useState('');
  const [ic_ext, setIc_ext] = useState('');

  const validationSchema = Yup.object().shape({
    identity_photo: Yup.mixed(),
    identity_card: Yup.mixed(),
    proof_of_residence: Yup.mixed(),
    selfie: Yup.mixed(),
  });

  const handleFileChange = (event, setStateFunction, setExtFunction, storageKey) => {
    const file = event.currentTarget.files[0];
  
    if (file) {
      const maxSize = 2097152;
      if (file.size > maxSize) {
        alert('File is too large. Please select a file smaller than 2MB.');
        return;
      }
      const fileUrl = URL.createObjectURL(file);
      setStateFunction(fileUrl);
  
      if (setExtFunction) {
        const extension = file.name.split('.').pop();
        setExtFunction(extension);
      }
      localStorage.setItem(storageKey, fileUrl);
    }
  };
  
  const handleDeleteFile = (setStateFunction) => {
    setStateFunction('');
  };

  const handleSubmit = () => {
    const identityPhotoFileUrl = localStorage.getItem('identityPhotoFileUrl');
    const identityCardFileUrl = localStorage.getItem('identityCardFileUrl');
    const proofOfResidenceFileUrl = localStorage.getItem('proofOfResidenceFileUrl');
    const selfieFileUrl = localStorage.getItem('selfieFileUrl');
  
    const files = {
      identityPhotoFile: identityPhotoFileUrl || identityPhotoFile,
      identityCardFile: identityCardFileUrl || identityCardFile,
      proofOfResidenceFile: proofOfResidenceFileUrl || proofOfResidenceFile,
      selfieFile: selfieFileUrl || selfieFile,
      por_ext: por_ext,
      ic_ext: ic_ext,
    };
  
    updateFormData(files);
  
    navigate('/creation-carte-infos-sign');
  };

  return (
    <>
      <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_back " to="/creation-carte-infos-parent">
        Retour
      </Link>
      <div className="containerfirsformid">
        <div className="containerFormId">
          <h1 className="titleform">Ajout des documents</h1>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              identity_photo: '',
              identity_card: '',
              proof_of_residence: '',
              selfie: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column p-3">
                    <h4 className="titleform">Photo d'identité</h4>
                    <Field name="identity_photo">
                      {({ field }) => (
                        <input
                          {...field}
                          className="input wInput"
                          type="file"
                          placeholder="identity_photo"
                          onChange={(event) => {
                            handleFileChange(event, setIdentityPhotoFile);
                            field.onChange(event);
                          }}
                        />
                      )}
                    </Field>
                    {identityPhotoFile && (
                      <div className="d-flex flex-column p-3">
                        {identityPhotoFile.endsWith('.pdf') ? (
                          <div className="d-flex flex-column align-items-center">
                            <p>
                              {identityCardFile}
                            </p>
                            <button className="btn mt-2" type="button btn-outline-danger" onClick={() => handleDeleteFile(setIdentityCardFile)}>
                              Supprimer
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex flex-column align-items-center">
                            <img src={identityPhotoFile} alt="Identity Photo" className="document-box" />
                            <button className="btn mt-2 btn-outline-danger" type="button" onClick={() => handleDeleteFile(setIdentityPhotoFile)}>
                              Supprimer
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column p-3">
                    <h4 className="titleform">Piéce d'identité</h4>
                    <Field name="identity_card">
                      {({ field }) => (
                        <input
                          {...field}
                          className="input wInput"
                          type="file"
                          placeholder="identity_card"
                          onChange={(event) => {
                            handleFileChange(event, setIdentityCardFile, setIc_ext);
                            field.onChange(event);
                          }}
                        />
                      )}
                    </Field>
                    {identityCardFile && (
                      <div className="d-flex flex-column p-3">
                        {identityCardFile.endsWith('.pdf') ? (
                          <div className="d-flex flex-column align-items-center">
                            <p>
                              {identityCardFile}
                            </p>
                            <button className="btn mt-2 btn-outline-danger" type="button" onClick={() => handleDeleteFile(setIdentityCardFile)}>
                              Supprimer                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <img src={identityCardFile} alt="Identity Card" className="document-box" />
                              <button className="btn mt-2 btn-outline-danger" type="button " onClick={() => handleDeleteFile(setIdentityCardFile)}>
                                Supprimer                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="d-flex flex-column p-3">
                    <h4 className="titleform">Justificatif de domicile</h4>
                    <Field name="proof_of_residence">
                      {({ field }) => (
                        <input
                          {...field}
                          className="input wInput"
                          type="file"
                          placeholder="proof_of_residence"
                          onChange={(event) => {
                            handleFileChange(event, setProofOfResidenceFile, setPor_ext);
                            field.onChange(event);
                          }}
                        />
                      )}
                    </Field>
                    {proofOfResidenceFile && (
                      <div className="d-flex flex-column p-3">
                        {proofOfResidenceFile.endsWith('.pdf') ? (
                          <div className="d-flex flex-column align-items-center">
                            <p>
                              {proofOfResidenceFile}
                            </p>
                            <button className="btn mt-2 btn-outline-danger" type="button " onClick={() => handleDeleteFile(setProofOfResidenceFile)}>
                              Supprimer                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <img src={proofOfResidenceFile} alt="Proof of Residence" className="document-box" />
                              <button className="btn mt-2 btn-outline-danger" type="button" onClick={() => handleDeleteFile(setProofOfResidenceFile)}>
                                Supprimer                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column p-3">

                    <div className="d-flex align-items-center position-relative">
                      <h4 className="titleform mr-3">Preuve de vivacité</h4>
                      <div className="position-relative">
                        <img
                          className="img-container"
                          src={QMark}
                          width="20"
                          height="20"
                          alt="?"
                        />
                        <div className="card-question position-absolute">
                          <div className="card-body p-3">
                            <p className="card-text">Pour prouver votre identité, nous avons besoin que vous nous envoyiez une photo de vous. Assurez-vous que votre visage est bien visible sur la photo et que vous êtes seul(e). Nous allons utiliser cette photo pour confirmer votre identité et assurer la sécurité de vos informations</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Field name="selfie">
                      {({ field }) => (
                        <input
                          {...field}
                          className="input wInput"
                          type="file"
                          placeholder="selfie"
                          onChange={(event) => {
                            handleFileChange(event, setSelfieFile);
                            field.onChange(event);
                          }}
                        />
                      )}
                    </Field>
                    {selfieFile && (
                      <div className="d-flex flex-column p-3">
                        {selfieFile.endsWith('.pdf') ? (
                          <div className="d-flex flex-column align-items-center">
                            <p>
                              {selfieFile}
                            </p>
                            <button className="btn mt-2 btn-outline-danger" type="button" onClick={() => handleDeleteFile(setSelfieFile)}>
                              Supprimer                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <img src={selfieFile} alt="Selfie" className="document-box" />
                              <button className="btn mt-2 btn-outline-danger" type="button" onClick={() => handleDeleteFile(setSelfieFile)}>
                                Supprimer                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  {identityPhotoFile && selfieFile && proofOfResidenceFile && identityCardFile ? <button className="btn btn-outline-secondary btn_sidebar widthBtnSidebar " type="submit">
                    Suivant
                  </button> :
                    <button className="btn btn-outline-secondary btn_sidebar widthBtnSidebar " disabled type="submit">
                      Suivant
                    </button>}

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>

  );
}