import React from 'react';
import logo from '../../assets/images/logo.png';

import { Link } from 'react-router-dom';

function ValidSinUp() {

  return (
    <div className="containerProfil">
      <img src={logo} className="imgprofil" alt="Example base64" />
      <h1 className="title">Félicitations pour votre inscription</h1>
      <h3 className="title">Un e-mail viens d'être envoyé pour vérifier votre e-mail</h3>
      <h3 className="title">Confirmer le pour vous connecter</h3>
      <Link className='navItem btn btn-outline-secondary mb-3 btn_sidebar_left' to="/">
              Se connecter
            </Link>
    </div>
  );
}

export default ValidSinUp;