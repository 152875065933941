import React, { useContext } from 'react';
import { useFormContext } from '../../context/FormContext';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

export default function Form2Recap() {
  const { formData } = useFormContext();
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
    <div className="containerHomeRecap">
      <div className="containerCard">
      <h2>Récapitulatif de vos informations</h2>
        <div className="cardWrap">
            <h3>Mes informations</h3>
            <div className="row p-1">
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nom:</p>
                    <p> {formData.name}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Deuxième nom:</p>
                    <p> {formData.second_name}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Prénom:</p>
                    <p> {formData.firstname}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Date de naissance:</p>
                    <p> {new Date(formData.date_birth).toLocaleDateString('fr-FR')}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nationalité:</p>
                    <p> {formData.nationality.label} ({formData.nationality.value})</p>
                    </div>
                    {formData.second_nationality && (
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Seconde nationalité:</p>
                        <p> {formData.second_nationality.label} ({formData.second_nationality.value})</p>
                    </div>
                    )}
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Sexe: </p>
                    <p> {formData.sex.label}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Ville de naissance:</p>
                    <p> {formData.city_birth}</p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Pays de naissance: </p>
                    <p>{formData.country_birth.label} ({formData.country_birth.value})</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Taille: </p>
                    <p>{formData.size} cm</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Couleur des yeux:</p>
                    <p> {formData.eyes_color.value}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Adresse:</p>
                    <p> {formData.adress}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Code postal:</p>
                    <p> {formData.postcode}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Ville:</p>
                    <p> {formData.city}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Pays:</p>
                    <p> {formData.country}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Téléphone:</p>
                    <p> {formData.phone}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="cardWrap">
            <h3>Parent 1 </h3>
                <div className="row">
                    <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Nom:</p><p> {formData.parent_name}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Date de naissance:</p><p> {new Date(formData.parent_date_birth).toLocaleDateString('fr-FR')}</p>
                    </div>
                    </div>
                    <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Prénom: </p><p>{formData.parent_firstname}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Lieu de naissance:</p><p> {formData.parent_place_birth.label}</p>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    <div className="d-flex flex-row p-1">
                        <p className='boldText'>Nationalité:</p><p> {formData.parent_nationality.label} </p>
                    </div>
                    </div>
                </div>
            </div>

            <div className="cardWrap">
            <h3>Parent 2 </h3>
            <div className="row">
                <div className="col-12 col-md-6">
                <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nom: </p><p>{formData.second_parent_name}</p>
                </div>
                <div className="d-flex flex-row p-1">
                    <p className='boldText'>Date de naissance:</p><p> {new Date(formData.second_parent_date_birth).toLocaleDateString('fr-FR')}</p>
                </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="d-flex flex-row p-1">
                    <p className='boldText'>Prénom: </p><p>{formData.second_parent_firstname}</p>
                </div>
                <div className="d-flex flex-row p-1">
                    <p className='boldText'>Lieu de naissance:</p><p> {formData.second_parent_place_birth.label}</p>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nationalité: </p><p>{formData.second_parent_nationality.label} </p>
                </div>
                </div>
            </div>
            </div>
      </div>
      <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/creation-carte-infos-documents">
        Suivant
        </Link>
    </div>
      </>
  );
}