import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

export default function ResetPassword() {
  const nav = useNavigate();

  const API_URL = 'https://admin.civis-libertatem.org/api/';

  const token = localStorage.getItem('token');

  //AXIOS CONFIGURATION
  let CONFIG_ = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (values.password !== values.confirmPassword) {
      setErrors({
        confirmPassword: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
      });
      return;
    }

    try {
      const response = await axios.post(
        API_URL + 'reset-password',
        {
          code: values.code,
          password: values.password,
        },
        CONFIG_
      );
      nav('/Accueil');
    } catch (error) {
      // handle error
    }
  };

  const ResetPasswordSchema = Yup.object().shape({
    code: Yup.string().required('Entrez le token de réinitialisation'),
    password: Yup.string().required('Entrez un nouveau mot de passe'),
    confirmPassword: Yup.string().required('Confirmation de mot de passe requise'),
  });

  return (
    <div className="containerLogin">
      <img src={logo} className={"logo"} alt="Logo" />
      <h1 className="title">Reinitialiser votre mot de passe</h1>
      <Formik
        initialValues={{
          code: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className="container-update-login">
            <div className="label-passwordUp">Entrez le token de réinitialisation</div>
            <Field className="input-login" type="text" placeholder="" name="code" required />
            <ErrorMessage name="code" component="div" className="confirm-error" />

            <div className="label-passwordUp">Entrez un nouveau mot de passe</div>
            <Field className="input-login" type="password" placeholder="Mot de passe" name="password" required />
            <ErrorMessage name="password" component="div" className="confirm-error" />

            <Field
              className="input-login"
              type="password"
              placeholder="Confirmation de mot de passe"
              name="confirmPassword"
              required
            />
            <ErrorMessage name="confirmPassword" component="div" className="confirm-error" />

            <button className="btn btn-outline-secondary widthBtnSidebar btn_sidebar" type="submit">
              Changer le Mot de Passe
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
