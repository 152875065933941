import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import React, { useContext, useState, useEffect } from 'react';
import { usePayment } from '../../context/PaymentContext';
import { useFormContext } from "../../context/FormContext";
import { Spinner } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import cb from '../../assets/images/cb.jpg';
import cadenas from '../../assets/images/cadenas.png';

import './Checkout.css';
import axios from 'axios';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

async function convertImageToBase64(blobUrl) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  const base64Image = await toBase64(blob);
  return base64Image;
}

const CheckoutForm = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { updateIsCheckout } = usePayment();

  useEffect(() => {
    updateIsCheckout(true);
    return () => {
      updateIsCheckout(false);
    };
  }, [updateIsCheckout]);

  const token = localStorage.getItem("access_token");
  const userId = localStorage.getItem("user_id");
  const API_URL = 'https://admin.civis-libertatem.org/api/';
  let CONFIG_ = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }

  const { formData, updateFormData } = useFormContext();
  const [paymentType, setPaymentType] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(5000);
  const { updatePaymentAmount } = usePayment();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret } = usePayment();
  const [loading, setLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
    if (event.target.value === '5') {
      const amount = 5000;
      setPaymentAmount(amount);
      updatePaymentAmount(amount); // Update paymentAmount in context
    }else if(event.target.value === '10'){
      const amount =  0;
      console.log(amount)
      
      setPaymentAmount(amount);
      updatePaymentAmount(amount); // Update paymentAmount in context
    } else {
      const amount = 2000;
      setPaymentAmount(amount);
      updatePaymentAmount(amount); // Update paymentAmount in context
    }
  };

  useEffect(() => {
    if (clientSecret) {
    }
  }, [paymentAmount]);

  const handleSubmit = async (event) => {
    const cardElement = elements.getElement(CardElement);
  if(userId == 6){
    updateFormData({ 'payment_id': userId});
    handleSubmitFor()
    setLoading(true)
    return;
  }

   event.preventDefault();
     
   setProcessing(true);
   if (!stripe || !elements) {
     return;
   }

   const result = await stripe.confirmCardPayment(clientSecret, {
     payment_method: {
       card: cardElement,
     },
   });

   if (result.error) {
     console.log("error ")
     setProcessing(false);
     setStripeErrorMessage(result.error.message);
   } else {
     if (result.paymentIntent.status === 'succeeded') {
       updateFormData({ 'payment_id': result.paymentIntent.id });
       handleSubmitFor()
       setLoading(true)
     }
   }
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleSubmitFor = async e => {
    const signatureFileBase64 = await convertImageToBase64(formData.signatureFile);
    const identityPhotoFileBase64 = await convertImageToBase64(formData.identityPhotoFile);
    const identityCardFileBase64 = await convertImageToBase64(formData.identityCardFile);
    const proofOfResidenceFileBase64 = await convertImageToBase64(formData.proofOfResidenceFile);
    const selfieFileBase64 = await convertImageToBase64(formData.selfieFile);
    let payload = {
      name: formData.name,
      second_name: formData.second_name,
      firstname: formData.firstname,
      date_birth: formData.date_birth,
      nationality: formData.nationality.label,
      cca2_nationality: formData.cca2_nationality ? formData.cca2_nationality.value : 'FR',
      second_nationality: formData.second_nationality.label,
      cca2_second_nationality: formData.cca2_second_nationality ? formData.cca2_second_nationality.value : 'FR',
      country_birth: formData.country_birth.label, //label bug
      cca2_country_birth: formData.cca2_country_birth ? formData.cca2_country_birth.value : 'FR', // value
      county_birth: formData.county_birth,
      adress: formData.adress,
      postcode: formData.postcode,
      city: formData.city,
      country: formData.country,
      phone: formData.phone,
      city_birth: formData.city_birth,
      sex: formData.sex.value,
      size: formData.size,
      eyes_color: formData.eyes_color.value,
      parent_name: formData.parent_name,
      parent_firstname: formData.parent_firstname,
      parent_date_birth: formData.parent_date_birth,
      parent_nationality: formData.parent_nationality.label,
      parent_place_birth: formData.parent_place_birth.label,
      cca2_parent_nationality: 'FR',
      second_parent_name: formData.second_parent_name,
      second_parent_firstname: formData.second_parent_firstname,
      second_parent_date_birth: formData.second_parent_date_birth,
      second_parent_nationality: formData.second_parent_nationality.label,
      second_parent_place_birth: formData.second_parent_place_birth.label,
      cca2_second_parent_nationality: 'FR',
      signature: signatureFileBase64,
      identity_photo: identityPhotoFileBase64,
      identity_card: identityCardFileBase64,
      proof_of_residence: proofOfResidenceFileBase64,
      selfie: selfieFileBase64,
      id_user: userId,
      payment_id: clientSecret ? clientSecret : 1,
      validity: paymentType,
      delivery_name: formData.name,
      delivery_adress: formData.adress,
      delivery_postcode: formData.postcode,
      delivery_city: formData.city,
      delivery_country: formData.country,
      por_ext: formData.por_ext,
      ic_ext: formData.ic_ext,
    };
    axios.post(API_URL + `card`, payload, CONFIG_)
      .then(function (response) {
        navigate('/creation-carte-infos-valide');
      }).catch(error => {
        navigate('/creation-carte-infos-valide');

      });
  };

  if (loading) {
    return (<div className="checkoutContainer">
      <Spinner animation="border" role="status" style={{ width: "5rem", height: "5rem" }}>
        <span className="sr-only"></span>
      </Spinner>
    </div>)
  }
  return (
    <>
    <div className="containerfirsformid">
      <div className="containerFormId">
        {/* <h1 className="titleform">Création d'une carte</h1> */}
      <form>
          <div>
            <input
              type="radio"
              id="paymentType5"
              name="paymentType"
              value="5"
              checked={paymentType === '5'}
              onChange={handlePaymentTypeChange}
            />
            <label for="paymentType5">Devenez un membre privilégié de notre association pour une durée de cinq ans, avec une unique contribution de 50 euros. Profitez d'une expérience enrichissante et soutenez nos actions tout au long de ces années.</label>
          </div>
          <div>
            <input
              type="radio"
              id="paymentType1"
              name="paymentType"
              value="1"
              checked={paymentType === '1'}
              onChange={handlePaymentTypeChange}
            />
            <label for="paymentType1">Adhérez à notre association pour une durée d'un an avec une participation financière modeste de 20 euros. Participez activement à nos activités et soutenez notre cause pendant une année complète.</label>
          </div>
          {userId == 6 && <div>
            <input
              type="radio"
              id="paymentType10"
              name="paymentType"
              value="10"
              checked={paymentType === '10'}
              onChange={handlePaymentTypeChange}
            />
            <label for="paymentType1">Vous avez une carte gratuit disponnible</label>
          </div>}
          
        </form>
      </div>
    </div>

{paymentType && (
    !formData.name ? (
            <div className="containerfirsformid">
              <div className="containerFormId">
                <div className="erreur-form-process mb-2">Une erreur est survenue lors du processus de creation de votre carte.</div>
              <Link as={Link} className="navItem btn btn-outline-secondary mt-3 mb-2 btn_back_final" to="/creation-carte-infos-generale">
                Retour
              </Link>
              </div>
            </div>
    ) :
      <div className="containerfirsformid">
        <div className="containerFormId">
        <form id="payment-form" onSubmit={handleSubmit}>
        {userId == 6  &&
        <p>
              <button
                id="submit"
                className='btn btn-outline-secondary btn_sidebar widthBtnSidebar m-3'
              >
                <span id="button-text">
                  {processing ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Créer votre carte"
                  )}
                </span>
              </button></p>
        }</form>
          <h1 className="titleform">Création d'une cartes</h1>
          <p className="textPayement">
            Bienvenue sur notre page de paiement sécurisé. Le montant total de votre achat est de {
              paymentAmount === 5000 ? '50€' : '20€'
            }. Nous acceptons les paiements par carte bancaire.
          </p>
          <p className="textPayement">
            Pour effectuer votre paiement, veuillez remplir les informations de votre carte bancaire dans le formulaire ci-dessous. Nous ne stockons pas les informations de votre carte bancaire sur notre site et toutes les transactions sont sécurisées grâce au protocole SSL.
          </p>
          <p className="textPayement">
            Une fois que vous avez rempli le formulaire, cliquez sur le bouton "Payer" pour procéder au paiement. Si le paiement est accepté, vous serez redirigé vers une page de confirmation.  </p>
          <p className="textPayement">
            Si vous avez des questions ou des préoccupations concernant votre paiement, n'hésitez pas à nous contacter à l'adresse email suivante : 	service.id@civis-libertatem.org.
          </p>
          <div className="checkoutContainer">

            <form id="payment-form" onSubmit={handleSubmit}>
              <p>Paiement securisé</p>
            <img src={cadenas} className="imgLogo" alt="Example base64" />
            <img src={cb} className="imgLogo" alt="Example base64" />
            <img src={mastercard} className="imgLogo" alt="Example base64" />
            <img src={visa} className="imgLogo" alt="Example base64" />
              <CardElement id="card-element" options={cardElementOptions} onChange={handleChange} />
              {stripeErrorMessage ? (
                <button className="btn btn-outline-secondary btn_sidebar widthBtnSidebar m-3" onClick={() => window.location.reload()}>
                  Une erreur est survenue, veuillez rafraîchir la page pour réessayer
                </button>
                ) : (
              <button
                 disabled={processing || disabled || succeeded}
                id="submit"
                className='btn btn-outline-secondary btn_sidebar widthBtnSidebar m-3'
              >
                <span id="button-text">
                  {processing ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Payer"
                  )}
                </span>
              </button>
              )}
              {error && (
                <div className="card-error" role="alert">
                  {error}
                </div>
              )}
              <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
                <a
                  href={`https://dashboard.stripe.com/test/payments`}
                >
                  {" "}
                  Stripe dashboard.
                </a> Refresh the page to pay again.
              </p>
            </form>
          </div>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            variant="outline-secondary"
          >
            Pourquoi un règlement est demandé lors de l’inscription ?
          </Button>
          <Collapse in={open}>
            <div id="example-collapse-text">
             <p>
             A la fin de votre inscription, nous vous invitons à régler un montant de 50 euros, il inclut : 
              </p> 
              <p>
              - La validation de votre inscription
              </p> 
              <p> 
              - L’ouverture de votre compte Civis Libertatem
              </p> 
              <p> 
              - La réception à votre domicile de votre pièce d’identité valide pour une durée de 5 ans
              </p> 
              La somme récoltée servira pour la gestion de votre dossier, le coût du matériel d’impression, la rémunération de nos équipes pour le travail effectué,
              le développement des projets des différents secteurs de l’organisation (éducation, économie, défense des libertés, etc…)
            </div>
          </Collapse>
        </div>
      </div>
    )}
    </>
  );
};

export default CheckoutForm;