import React, { useRef, useState, useEffect, useContext } from 'react';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import { useNavigate} from 'react-router-dom';
import { useFormContext } from "../../context/FormContext";

const libraries = ['places'];

function Form5() {
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { formData, updateFormData } = useFormContext();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk', // Remplacez par votre clé API Google
    libraries,
  });

  useEffect(() => {
    if(!isAuthenticated){
      navigate('/');
    }
  }, []);

  const [searchBox, setSearchBox] = useState(null);
  const [billingAddress, setBillingAddress] = useState('');

  const onSearchBoxMounted = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      setBillingAddress(places[0].formatted_address);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateFormData(billingAddress);
    navigate('/Checkout');

  };

  if (loadError) {
    return <div>Erreur lors du chargement de l'autocomplétion</div>;
  }

  if (!isLoaded) {
    return <div>Chargement de l'autocomplétion...</div>;
  }

  return (
    <div className="containerHome">
      <form onSubmit={handleSubmit}>
        <h1>
                Adresse de Livraison
            </h1>
          <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={onSearchBoxMounted}>
            <input
              type="text"
              placeholder="Rechercher une adresse"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `20vw`,
                height: `32px`,
                marginTop: `27px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
              }}
            />
          </StandaloneSearchBox>
              <div className='p-5'>
              {billingAddress}
              </div>
            <button className='btn btn-outline-secondary btn_sidebar widthBtnSidebar' type="submit">Passer au paiement</button>

      </form>
    </div>
  );
}

export default Form5;