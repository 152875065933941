import React, { useState, useEffect } from 'react';
import articles from '../../assets/json/articles.json';

export default function Information() {
  // DATA
  const [actu, setActu] = useState([]);

  useEffect(() => {
    setActu(articles);
  }, []);

  return (
  <div className="containerHomeRecap">
    <div className="containerCard">
      <h1 className="title">Actualités</h1>
      <div className="cardWrap-actu">
        {actu.map(article => (
          <div key={article.id} className="card-custom-actu">
            <img src={article.image} alt={article.title} />
            <h3>{article.title}</h3>
            <p>{article.summary}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}
