import React, { createContext, useState, useEffect } from 'react';

const CardContext = createContext();

const CardProvider = ({ children }) => {
    const [Card, setCard] = useState();
    const [PhotoID, setPhotoID] = useState();

    const getCard = async () => {
        try {
            const res = await fetch('https://admin.civis-libertatem.org/api/usercard', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                },
            }).then(res => res.json());
            const values = Object.values(res);
            const lastElement = values.pop(); 
            localStorage.setItem('cardStorage', lastElement);
            setCard(lastElement);
            getPhotoID(localStorage.getItem('access_token'),lastElement);
            
        } catch (error) {
            console.log(error);
        }
    };

    const getPhotoID = async (token, lastElement) => {
        try {
            const res = await fetch('https://admin.civis-libertatem.org/api/showAll', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            }).then(res => res.json());
            setPhotoID(res[lastElement.id]);
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     if (Card == null){
    //         getCard();
    //     }
    //     if (PhotoID == null){
    //         getPhotoID();
    //     }
    //   }, []);

    return (
        <CardContext.Provider value={{ Card, setCard, getCard, PhotoID, setPhotoID, getPhotoID }}>
            {children}
        </CardContext.Provider>
    );
};

export { CardContext, CardProvider };