import React, { useContext,useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import logo from '../../assets/images/logo.png';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const SignupSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Nom est obligatoire'),

    name: Yup.string()
      .required('Prenom est obligatoire'),

    phoneNumber: Yup.string()
      .required('Numero de téléphone obligatoire'),

    email: Yup.string()
      .email("Entrer un format d'email valide")
      .required('Email est obligatoire'),

    password: Yup.string()
      .matches(/\w*[a-z]\w*/,  "Lettre miniscule obligatoire")
      .matches(/\w*[A-Z]\w*/,  "Lettre majuscule obligatoire")
      .min(8, ({ min }) => `Le mot de passe doit contenir minimum ${min} caractères`)
      .required('Le mot de passe est obligatoire'),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Les 2 mots de passe ne correspondent pas ')
      .required('Confirmer votre mot de passe'),
  });

  return (
    <div className="containerLogin">
      <img src={logo} className={"logo"} alt="Logo" />

      <h1 className="title">S'inscrire</h1>
      <div className="form-wrapper">
        <Formik
          initialValues={{
            firstname: '',
            name: '',
            phoneNumber: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            const successOrError = await register(values);
            if (successOrError === true) {
              navigate('/inscription-valide');
            } else if (typeof successOrError === 'string') {
              setErrorMessage(successOrError);
            } else {
              console.log(successOrError);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="form">
              <label htmlFor="firstname">Prénom</label>
              <Field className="input" name="firstname" />
              {errors.firstname && touched.firstname ? (
                <div className="error">{errors.firstname}</div>
              ) : null}

              <label htmlFor="name">Nom</label>
              <Field className="input" name="name" />
              {errors.name && touched.name ? (
                <div className="error">{errors.name}</div>
              ) : null}

              <label htmlFor="phoneNumber">Numéro de téléphone</label>
              <Field className="input" name="phoneNumber" />
              {errors.phoneNumber && touched.phoneNumber ? (
                <div className="error">{errors.phoneNumber}</div>
              ) : null}

              <label htmlFor="email">Email</label>
              <Field className="input" name="email" type="email" />
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}

              <label htmlFor="password">Mot de passe</label>
              <Field className="input" name="password" type="password" />
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : null}

              <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
              <Field className="input" name="confirmPassword" type="password" />
              {errors.confirmPassword && touched.confirmPassword ? (
                <div className="error">{errors.confirmPassword}</div>
              ) : null}

              <button className="btn btn-outline-secondary widthBtnSidebar btn_sidebar" type="submit">
                Envoyer
              </button>

              {errorMessage ? (
                <div className="error">{errorMessage}</div>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SignUp;