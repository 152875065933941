import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CardContext } from '../../context/CardContext';

export default function FormFinal() {
  const { Card, PhotoID, getCard, getPhotoID } = useContext(CardContext);

  const nav = useNavigate();
  useEffect(() => {

      if (!Card) {
        getCard();
      }
    
  }, [Card, getCard]);
  return (
    <>
      <div className="containerfirsformid">
        <div className="containerFormId">
          <h1 className="titleform">Félicitations !</h1>
          <p className="textPayement">
            Nous sommes heureux de vous informer que votre commande de carte d'identité a été validée avec succès. Nous avons reçu toutes les informations nécessaires ainsi que le paiement correspondant.</p>
          <p className="textPayement">

            Nous allons maintenant traiter votre commande et vous tenir informé(e) de son avancement. Vous recevrez un e-mail de confirmation lorsque votre carte sera prête à être expédiée.</p>
          <p className="textPayement">

            Si vous avez des questions ou des préoccupations concernant votre commande, n'hésitez pas à nous contacter. Nous sommes là pour vous aider à tout moment : service.id@civis-libertatem.org.
          </p>
          <p className="textPayement">
            Nous vous remercions pour votre confiance.
          </p>
          <p className="textPayement">
            Cordialement, L'équipe Civis Libertatem
          </p>
          <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_back_final " to="/Accueil">
            Retour
          </Link>
        </div>
      </div>

    </>
  );

}