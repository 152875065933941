import React, { useContext,useEffect, useState} from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { CardContext } from '../../context/CardContext';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function SignIn() {
  const { login } = useContext(AuthContext);
  const { getCard } = useContext(CardContext);

  const [formVisible, setFormVisible] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  }

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [sentMail, setSentMail] = useState(false);


  const API_URL = "https://admin.civis-libertatem.org/api/";
  let CONFIG_ = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const handleForgotPassword = async (values) => {
    try {
      const response = await axios.post(API_URL + 'forgot-password', {
        email: values.resetEmail,
      }, CONFIG_);
  
      if(response.data.message === 'Reset code has been sent to your email!') {
        setResetMessage("Un code de réinitialisation vous à été envoyé par email!");
        setTimeout(() => {
          navigate('/Accueil');
        }, 10000);
        setSentMail(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setResetMessage("Email invalide");
      } else if (error.message === 'Request failed with status code 404') {
        setResetMessage("Cet email n'est pas reconnu.");
      } else {
        setResetMessage("Une erreur s'est produite. Veuillez réessayer.");
      }
    }
  };
  

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Requis'),
    password: Yup.string()
      .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
      .required('Requis'),
  });

  return (
    <div className="containerLogin">
      <img src={logo} className={"logo"} alt="Logo" />
      <h1 className="title">Se connecter</h1>
      {!formVisible ? (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={async (values) => {
            const success = await login(values.email, values.password);
            const success2 = await getCard();
            if (typeof success === 'string') {
              setErrorMessage(success);
            } else if (success) {
              navigate('/Accueil');
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="form">
              <label htmlFor="email">Email</label>
              <Field className="input" name="email" type="email" />
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}

              <label htmlFor="password">Mot de passe</label>
              <Field className="input" name="password" type="password" />
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : null}

              <button className="btn btn-outline-secondary widthBtnSidebar btn_sidebar" type="submit">
                Se connecter
              </button>
              {errorMessage && <div className="error">{errorMessage}</div>}
              <div
                  className="label-passwordUp clickable-link"
                  onClick={handleToggleForm}
                >
                  Mot de passe oublié ?
                </div>
            </Form>
          )}
        </Formik>
            ) : (
            <Formik
              initialValues={{
                resetEmail: '',
              }}
              onSubmit={handleForgotPassword}
            >
              {({ errors, touched }) => (
                <Form className="container-login">
                  <label htmlFor="resetEmail">Réinitialisez votre mot de passe :</label>
                  <Field className="input" name="resetEmail" type="email"/>
                  <button className="btn btn-outline-secondary widthBtnSidebar btn_sidebar" type="submit">Réinitialiser</button>
                  {resetMessage && <div className="error">{resetMessage}</div>}
                  <div
                    className="label-passwordUp clickable-link"
                    onClick={handleToggleForm}
                    disabled={sentMail}
                  >
                    Retour à la connexion
                  </div>
                </Form>
              )}
            </Formik>
      )}
    </div>
  );
}

export default SignIn;