import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import logo from '../../assets/images/logo.png';
import { useNavigate, link } from 'react-router-dom';


function Profil() {

  const { isAuthenticated, user } = useContext(AuthContext);
  

  const navigate = useNavigate();

  useEffect(() => {
      if(!isAuthenticated){
        navigate('/');
      }
    }, []);

  return (
        <div className="containerProfil">
          <img src={logo} className="imgprofil" alt="Example base64" />
          <h1 className="title">Profil</h1>
          {user && (
            <div className='wrapperProfil'>
              <p>Nom: {user?.firstname}</p>
              <p>Prénom: {user?.name}</p>
              <p>Email:  {user?.email}</p>
              <p>Téléphone:   {user?.phone}</p>
            </div>
          )}
        </div>
  );
}

export default Profil;