import React, { useContext, useState, useEffect } from 'react';
import { useFormContext } from "../../context/FormContext";
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import countries from 'countries-list';


const CustomSelect = ({ field, form, options, ...props }) => {
    const handleChange = (value) => {
        form.setFieldValue(field.name, value);
    };

    const handleBlur = (evt) => {
        if ('ontouchstart' in window) {
            evt.preventDefault();
        } else {
            form.setFieldTouched(field.name, true);
        }
    };

    return (
        <Select
            {...field}
            {...props}
            options={options}
            onChange={handleChange}
            // onBlur={() => form.setFieldTouched(field.name, true)}
            onBlur={handleBlur}
        />
    );
};

const CustomError = ({ name, touched, errors }) => {
    if (!touched[name] || !errors[name]) return null;
    return <div className="text-danger">{errors[name]}</div>;
};
const countryOptions = Object.entries(countries.countries).map(([code, country]) => ({
    value: code,
    label: country.name
}));

function Form2() {

    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const { formData, updateFormData } = useFormContext();

    const navigate = useNavigate();

    useEffect(() => {
        if(!isAuthenticated){
          navigate('/');
        }
      }, []);

    const validationSchema = Yup.object().shape({
        parent_name: Yup.string().required('Nom est requis'),
        parent_firstname: Yup.string().required('Prénom est requis'),
        parent_date_birth: Yup.date().required('Date de naissance est requise'),
        parent_nationality: Yup.object().required('Nationalité est requise'),
        parent_place_birth: Yup.object().required('Pays de naissance est requis'),
       
      });
      

    return (
        <>
            <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_back " to="/creation-carte-infos-generale">
                Retour
            </Link>
            <div className="containerfirsformid">

                <div className="containerFormId">

                    <h1 className="titleform"></h1>

                    <Formik
                        initialValues={{
                            parent_name: '',
                            parent_firstname: '',
                            parent_date_birth: '',
                            parent_nationality: '',
                            parent_place_birth: '',

                            second_parent_name: '',
                            second_parent_firstname: '',
                            second_parent_date_birth: '',
                            second_parent_nationality: '',
                            second_parent_place_birth: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            updateFormData(values);
                            navigate('/creation-carte-infos-recap');

                        }}>

                        {({ isSubmitting, errors, touched }) => (
                            <Form>

                                <h1 className="titleform">Parent 1</h1>

                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column p-3">
                                        <label htmlFor="parent_date_birth" className="input-label">Nom </label>
                                        <Field className="input wInput" type="text" name="parent_name" />
                                        <ErrorMessage className="text-danger" name="parent_name" component="div" />
                                    </div>
                                    <div className="d-flex flex-column  p-3">
                                        <label htmlFor="parent_date_birth" className="input-label">Prénom </label>
                                        <Field className="input" type="text" name="parent_firstname" />
                                        <ErrorMessage className="text-danger" name="parent_firstname" component="div" />
                                    </div>
                                </div>

                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column p-3">
                                        <label htmlFor="parent_date_birth" className="input-label">
                                            Date de naissance du
                                        </label>
                                        <Field
                                            className="input"
                                            type="date"
                                            name="parent_date_birth"
                                            id="parent_date_birth"
                                        />
                                        <ErrorMessage className="text-danger" name="name" component="div" />
                                    </div>
                                    <div className="d-flex flex-column  p-3">
                                        <label htmlFor="parent_nationality" className="input-label">
                                            Nationalité
                                        </label>
                                            <Field
                                                placeholder="Sélectionner"
                                                className="input_country"
                                                name="parent_nationality"
                                                id="parent_nationality"
                                                component={CustomSelect}
                                                options={countryOptions}
                                            />
                                            <CustomError name="parent_nationality" touched={touched} errors={errors} />
                                    </div>
                                   
                                </div>

                                <div className="d-flex flex-row ">
                                <div className="d-flex flex-column  p-3">
                                        <label htmlFor="parent_place_birth" className="input-label">
                                            Pays de naissance
                                        </label>
                                            <Field
                                                placeholder="Sélectionner"
                                                className="input_country"
                                                name="parent_place_birth"
                                                id="parent_place_birth"
                                                component={CustomSelect}
                                                options={countryOptions}
                                            />
                                            <CustomError name="parent_place_birth" touched={touched} errors={errors} />
                                    </div>
                                   
                                </div>
                           

                                <h1 className="titleform">Parent 2</h1>

                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column p-3">
                                        <label htmlFor="parent_date_birth" className="input-label">Nom </label>
                                        <Field className="input wInput" type="text" name="second_parent_name" />
                                        <ErrorMessage className="text-danger" name="second_parent_name" component="div" />
                                    </div>
                                    <div className="d-flex flex-column  p-3">
                                        <label htmlFor="parent_date_birth" className="input-label">Prénom </label>
                                        <Field className="input" type="text" name="second_parent_firstname" />
                                        <ErrorMessage className="text-danger" name="second_parent_firstname" component="div" />
                                    </div>
                                </div>

                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column p-3">
                                        <label htmlFor="second_parent_date_birth" className="input-label">
                                            Date de naissance du 
                                        </label>
                                        <Field
                                            className="input"
                                            type="date"
                                            name="second_parent_date_birth"
                                            id="second_parent_date_birth"
                                        />
                                        <ErrorMessage className="text-danger" name="name" component="div" />
                                    </div>
                                    <div className="d-flex flex-column  p-3">
                                        <label htmlFor="second_parent_nationality" className="input-label">
                                            Nationalité 
                                        </label>
                                            <Field
                                                placeholder="Sélectionner"
                                                className="input_country"
                                                name="second_parent_nationality"
                                                id="second_parent_nationality"
                                                component={CustomSelect}
                                                options={countryOptions}
                                            />
                                            <CustomError name="second_parent_nationality" touched={touched} errors={errors} />
                                    </div>
                                   
                                </div>
                                <div className="d-flex flex-row">
                                <div className="d-flex flex-column  p-3">
                                        <label htmlFor="second_parent_place_birth" className="input-label">
                                            Pays de naissance 
                                        </label>
                                            <Field
                                                placeholder="Sélectionner"
                                                className="input_country"
                                                name="second_parent_place_birth"
                                                id="second_parent_place_birth"
                                                component={CustomSelect}
                                                options={countryOptions}
                                            />
                                            <CustomError name="second_parent_place_birth" touched={touched} errors={errors} />
                                    </div>
                                    <div className="d-flex flex-column  p-3">
                                     
                                    </div>
                                   
                                </div>
                             

                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-outline-secondary btn_sidebar widthBtnSidebar " type="submit">
                                        Suivant
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div >
            </div >
        </>
    );
}

export default Form2;