// App.js
import React, { useContext, useState, useEffect } from 'react';
import { FormProvider } from "./context/FormContext";
import { PaymentProvider } from './context/PaymentContext';
import { CardProvider , CardContext } from './context/CardContext';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from './components/home/Home';
import SignUp from './components/user/SignUp';
import ValidSinUp from './components/user/ValidSinUp';
import SignIn from './components/user/SignIn';
import Form1 from './components/form/Form1';
import Form2 from './components/form/Form2';
import Form2Recap from './components/form/Form2Recap';
import Form4 from './components/form/Form4';
import Form5 from './components/form/Form5';
import Form3Image from './components/form/Form3Image';
import Checkout from './components/form/Checkout';
import FormFinal from './components/form/FormFinal';
// import FormSubmit from './components/form/FormSubmit.jsx_';
import ScrollToTop from './components/form/ScrollToTop';
import ResetPassword from './components/user/ResetPassword';
import logo from './assets/images/logo.png';
import civisLogoo from './assets/images/civisLogoo.png';
import MenuTop from './components/nav/MenuTop';
import Information from './components/information/Information';
import Profil from './components/user/Profil';
import Profilicon from '../src/assets/images/profil.png';
import Ajoutericon from '../src/assets/images/ajouter.png';
import Deconnexionicon from '../src/assets/images/deconnexion.png';
import Accueilicon from '../src/assets/images/accueil.png';

import './App.css';

const stripePromise = loadStripe('pk_live_51N1XzUDLKftUHw7vbfYzxNHZjyNdstlVF7JXt58DRN93doSslOewkXWj5CDxL8IkbcTOgF8WDSWxO1MNCwyglQyT00zCaXBZ2v');
// dev key below
//const stripePromise = loadStripe('pk_test_51N1XzUDLKftUHw7vbwPdbXiwJf9Cgb5pGSrXrcATben3U3hOxdwFldtZdvtv0mM3fFXy55NtbtlZIZpEtHNMjgVd00xu6Q2XDG');

function App() {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const {Card } = useContext(CardContext);
  const navigate = useNavigate();

  useEffect(() => {
  }, [localStorage.getItem("access_token")]);

  function disconnectedUser() {
    localStorage.clear();
    navigate('/');
    setIsAuthenticated(false)
  }
  return (
    <>

      <MenuTop />
      <nav className="sidebar">

        {!isAuthenticated ? (
          <>
            <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/">
              Connexion
            </Link>
            <Link className='navItem btn btn-outline-secondary mb-3 btn_sidebar_left' to="/signup">
              Inscription
            </Link>

          </>
        )
          :
          (
            <>
              <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/Accueil">
                <p>Accueil</p> <img src={Accueilicon} className="class_icon" alt="Civis Libertatem icon accueil" />
              </Link>
              {!Card &&               <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/creation-carte-infos-generale">
              <p>Créer une carte</p> <img src={Ajoutericon} className="class_icon" alt="Civis Libertatem icon carte" />
              </Link>}


              <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/Actualites">
                Actualités
              </Link>
              <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/Profil">
              <p>Profil</p> <img src={Profilicon} className="class_icon" alt="Civis Libertatem icon profil" />
              </Link>
              <div
                className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left"
                onClick={() => disconnectedUser()}
              >
                <p>Déconnexion</p> <img src={Deconnexionicon} className="class_icon" alt="Civis Libertatem icon deconnexion" />
              </div>
            </>
          )}
      </nav>
      {/* <AuthProvider> */}
      <FormProvider>
      <PaymentProvider>
      <ScrollToTop />
        <Routes>
          <Route path="/Accueil" element={<Home />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/inscription-valide" element={<ValidSinUp />} />
          <Route path="/creation-carte-infos-generale" element={<Form1 />} />
          <Route path="/creation-carte-infos-parent" element={<Form2 />} />
          <Route path="/creation-carte-infos-recap" element={<Form2Recap />} />
          <Route path="/creation-carte-infos-sign" element={<Form4 />} />
          <Route path="/creation-carte-infos-adress" element={<Form5 />} />
          <Route path="/creation-carte-infos-documents" element={<Form3Image />} />
          {/* <Route path="/creation-carte-infos-submit" element={<FormSubmit />} /> */}
          <Route path="/Profil" element={<Profil />} />
          <Route path="/Actualites" element={<Information />} />
          <Route path="/checkout" element={<Elements stripe={stripePromise}><Checkout /></Elements>} />
          <Route path="/creation-carte-infos-valide" element={<FormFinal />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </PaymentProvider>
      </FormProvider>
      {/* </AuthProvider> */}

    </>
  );
}

export default App;