import React, { useState, useRef, useContext, useEffect } from 'react';
import { useFormContext } from "../../context/FormContext";
import { AuthContext, AuthProvider } from '../../context/AuthContext';
import SignaturePad from 'react-signature-canvas';
import pdf1 from "../../assets/pdf/charte-du-citoyen-2.pdf";
import pdf2 from "../../assets/pdf/Charte-Civis-Libertatem-1.pdf";
import pdf3 from "../../assets/pdf/protection-donnee.pdf";
import { useNavigate } from 'react-router-dom';

const Form4 = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { formData, updateFormData } = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, []);

  const [trimmedDataURL, setTrimmedDataURL] = useState('');
  const sigPad = useRef({});

  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [checkBox4, setCheckBox4] = useState(false);
  const [pdfPopup, setPdfPopup] = useState(false);
  const [pdfSource, setPdfSource] = useState("");

  const clear = () => {
    sigPad.current.clear();
  };

  const trim = () => {
    setTrimmedDataURL(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const handlePdfClick = (pdfUrl) => {
    setPdfSource(pdfUrl);
    setPdfPopup(true);
  };

  const closePdfPopup = () => {
    setPdfPopup(false);
  };

  const handleContinue = () => {
    const files = {
      signatureFile: trimmedDataURL,
    };
    if (checkBox1 && checkBox2 && checkBox3 && trimmedDataURL) {
      updateFormData(files);
      navigate('/Checkout');
    }
  };

  return (
    <div className="form4-container">
      <div className="signature-section">
      <div id="signature-enfant-background-white">
        <SignaturePad
          penColor="black"
          backgroundColor="transparent"
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          ref={sigPad}
        />
        </div>
        <div className="signature-actions">
          <button onClick={clear} className="btn btn-outline-secondary btn_sidebar widthBtnSidebar">Remettre a zéro</button>
          <button onClick={trim} className="btn btn-outline-secondary btn_sidebar widthBtnSidebar">Validé</button>
        </div>
      </div>
      <p> Votre signature sera présente sur la carte <br/></p>
      {trimmedDataURL ? 'Signature enregistrer' : "Merci de signer avant de continuer"}
      <div className="certifieHonorText">
        <input
          type="checkbox"
          id="checkbox1"
          checked={checkBox4}
          onChange={(e) => setCheckBox4(e.target.checked)}
        />
        Je certifie sur l'honneur que les renseignements fournis sont complets et exacts et
        pris connaissance des sanctions pénales encourues pour fausse déclaration, faux et usage de faux.
      </div>
      <h3 className="mt-5"> Accepter les chartes</h3>
      <div className="checkbox-section mt-5">
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="checkbox1"
            checked={checkBox1}
            onChange={(e) => setCheckBox1(e.target.checked)}
          />
          <label
            htmlFor="checkbox1"
            onClick={() => handlePdfClick(pdf1)}
            className="btn btn-outline-secondary btn_sidebar btnCharte"
          >
            CHARTE DU CITOYEN
          </label>
        </div>
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="checkbox2"
            checked={checkBox2}
            onChange={(e) => setCheckBox2(e.target.checked)}
          />
          <label
            htmlFor="checkbox2"
            onClick={() => handlePdfClick(pdf2)}
            className="btn btn-outline-secondary btn_sidebar btnCharte"
          >
            CHARTE DE L’ORGANISATION
          </label>
        </div>
        <div className="checkbox-item">
          <input
            type="checkbox"
            id="checkbox3"
            checked={checkBox3}
            onChange={(e) => setCheckBox3(e.target.checked)}
          />
          <label
            htmlFor="checkbox3"
            onClick={() => handlePdfClick(pdf3)}
            className="btn btn-outline-secondary btn_sidebar btnCharte"
          >
            PROTECTION DES DONNEES
          </label>
        </div>
      </div>
      <button onClick={handleContinue} disabled={!checkBox1 || !checkBox2 || !checkBox3 || !trimmedDataURL || !checkBox4} className="btn btn-outline-secondary btn_sidebar widthBtnSidebar">
        Continuer
      </button>
      {pdfPopup ? (
        <div className="pdf-popup">
          <div className="pdf-popup-content">
            <button onClick={closePdfPopup} className="btn btn-outline-secondary btn_sidebar widthBtn">Fermer</button>
            <iframe
              src={pdfSource}
              title="PDF"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Form4;
