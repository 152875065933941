import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("access_token"));
  const [user, setUser] = useState({});

  const login = async (email, password) => {
    try {
      const response = await fetch('https://admin.civis-libertatem.org/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
        if(response.status === 401) {
        return 'Identifiant ou mot de passe érroné';
      } else if (response.ok) {
        const data = await response.json();
        localStorage.setItem("access_token", data.access_token)
        setIsAuthenticated(data.access_token);
        getUser(data.access_token);
        // if(!emailVerified){
        //     return 'Vous devez vérifier votre adresse e-mail pour activer votre compte.';
        // }
        return true;
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const register = async (inscritpionForm) => {
    try {
      const response = await fetch('https://admin.civis-libertatem.org/api/register', {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
        body: JSON.stringify({
          name: inscritpionForm.name,
          firstname: inscritpionForm.firstname,
          email: inscritpionForm.email,
          phone: inscritpionForm.phoneNumber,
          password: inscritpionForm.password,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data.data) {
          return true;
        } else if (data.email == 'The email has already been taken.') {
          return 'Cette adresse email est déjà utilisée.';
        } else {
          console.log(data);
        }
      } else {
        console.error('Register failed');
      }
    } catch (error) {
      console.error('Register error:', error);
      return error.message;
    }
  };

  const getUser = async (access_token) => {
    try {
      const res = await fetch('https://admin.civis-libertatem.org/api/user', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access_token,
        },
      });
        const data = await res.json();
        localStorage.setItem("user_id", data.id)
        setUser(data);
    } catch (error) {
      console.error('Get user error:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUser(isAuthenticated);
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, register, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };