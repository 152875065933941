import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from "react-bootstrap";

const CardComponent = (props) => {

    // DATA
    const [cardSerialNumber, setCardSerialNumber] = useState([]);
  
    const token = localStorage.getItem("access_token");
    const API_URL = 'https://admin.civis-libertatem.org/';
    const CONFIG_ = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    };
  
    useEffect(() => {
      axios.get(API_URL + `cardSerialNumber/${props.Card.id}`, CONFIG_)
        .then(res => {
          setCardSerialNumber(res.data);
        })
        .catch(err => {
          // Handle error
        });
    }, []);


  return (
    <div className='d-flex position-relative justify-content-start align-items-center w-[426px] min-h-[600px] bg-[#e3e2df] rounded-[30px] border-[4px] border-white px-[40px] py-[24px] cursor-grab relative bgCard'>
      <div className="mr-3 d-flex align-items-center" style={{ width: "100px", height: "150px" }}>
        {props.PhotoID ? (
          <img src={`data:image/jpeg;base64,${props.PhotoID}`} className="imgCardId" alt="d'identité" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        ) : (
          <Spinner animation="border" role="status" style={{ width: "2rem", height: "2rem" }}>
            <span className="sr-only"></span>
          </Spinner>
        )}
      </div>
      <div className="d-flex flex-column justify-content-end">
        <div className="textCardId">Nom : {props.Card.name}</div>
        <div className="textCardId">Prénom : {props.Card.firstname}</div>
        <div className="textCardId">Date de naissance : {new Date(props.Card.date_birth).toLocaleDateString('fr-FR')}</div>
        {/* <div className="textCardId-center-mt">Numéro d'identité</div>
        <div className="textCardId-center">En cours de traitement</div> */}
      </div>
    </div>
  );
};

export default CardComponent;