import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CardContext } from '../../context/CardContext';
import { AuthContext, AuthProvider } from '../../context/AuthContext';
// import { motion, useAnimation } from 'framer-motion';
import CardComponent from './Card'
function Home() {
 
  const nav = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { Card, PhotoID, getCard } = useContext(CardContext);
  const [base64Image, setBase64Image] = useState(false);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    if (!isAuthenticated) {
      nav('/');
    }
    if (!Card) {
      getCard();
    }
  }, [Card, getCard]);

  return (
    <div className="containerHome">

      {Card ?
        <>

          <div className="containerCard">

            <div key={Card.id}>
                <CardComponent Card={Card} PhotoID={PhotoID} />
              {/* Debut */}
              <div className="cardWrap">
                <h3>Mes informations</h3>
                <div className="row p-1">
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nom:</p>
                    <p> {Card.name}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Deuxième nom:</p>
                    <p> {Card.second_name}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Prénom:</p>
                    <p> {Card.firstname}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Date de naissance:</p>
                    <p> {new Date(Card.date_birth).toLocaleDateString('fr-FR')}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Nationalité:</p>
                    <p> {Card.nationality}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    {Card.second_nationality && <><p className='boldText'>Seconde nationalité:</p><p> {Card.second_nationality}</p></>}
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Sexe: </p><p> {Card.sex === 1 ? 'Homme' : "femme"}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Ville de naissance:</p>
                    <p> {Card.city_birth}</p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Pays de naissance: </p>
                    <p>{Card.country_birth}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Taille: </p>
                    <p>{Card.size} cm</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Couleur des yeux:</p>
                    <p> {Card.eyes_color.value}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Adresse:</p>
                    <p> {Card.adress}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Code postal:</p>
                    <p> {Card.postcode}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Ville:</p>
                    <p> {Card.city}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Pays:</p>
                    <p> {Card.country}</p>
                    </div>
                    <div className="d-flex flex-row p-1">
                    <p className='boldText'>Téléphone:</p>
                    <p> {Card.phone}</p>
                    </div>
                </div>
            </div>
              </div>

              <div>
                <div>

                  <div className="cardWrap">
                    <h3>Parent 1 </h3>

                    <div className="row p-1">

                      <div className="col-12 col-md-6">
                        <div className="d-flex flex-row p-1">
                          <p className='boldText'>Nom:</p><p> {Card.parent_name}</p>
                        </div>
                        <div className="d-flex flex-row ">
                          <p className='boldText'>Date de naissance:</p><p> {new Date(Card.parent_date_birth).toLocaleDateString('fr-FR')}</p>

                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex flex-row p-1">
                          <p className='boldText'>Prénom: </p><p>{Card.parent_firstname}</p>
                        </div>
                        <div className="d-flex flex-row p-1">
                          <p className='boldText'>Lieu de naissance:</p><p> {Card.parent_place_birth}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-row p-1">
                          <p className='boldText'>Nationalité:</p><p> {Card.parent_nationality}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Card.second_parent_name &&
                    <div className="cardWrap">
                      <h3>Parent 2 </h3>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="d-flex flex-row p-1">
                            <p className='boldText'>Nom: </p><p>{Card.second_parent_name}</p>
                          </div>
                          <div className="d-flex flex-row p-1">
                            <p className='boldText'>Date de naissance:</p><p> {new Date(Card.second_parent_date_birth).toLocaleDateString('fr-FR')}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="d-flex flex-row p-1">
                            <p className='boldText'>Prénom: </p><p>{Card.second_parent_firstname}</p>
                          </div>
                          <div className="d-flex flex-row p-1">
                            <p className='boldText'>Lieu de naissance:</p><p> {Card.second_parent_place_birth}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-row p-1">
                            <p className='boldText'>Nationalité: </p><p>{Card.second_parent_nationality}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          </>
          :
          <>
          <h3 className="title">Aucune Carte disponible</h3>
          <p>Félicitations pour votre inscription, nous vous remercions pour votre soutien.
Vous pouvez désormais accéder aux dernières actualités de l’organisation et au formulaire de création de votre ID Civis Libertatem.</p>
          <Link as={Link} className="navItem btn btn-outline-secondary mb-3 btn_sidebar_left" to="/creation-carte-infos-generale">
            Créer une carte
          </Link>
          </>
        }
    </div>
  );
}

export default Home;